import services_api from './auth';


function fetchRecords(code, done) {
    // //console.log('code: ' + code);
    if (code !== '') {
        services_api.check_code(code)
            .then(response => {
                ////console.log('check_code :' + JSON.stringify(response.data));
                done(response.data);
            })
            .catch(error => {
                // //console.log('check_code: ' + error);
                done(null);
            });
    } else {
        services_api.fetch_data()
            .then(response => {
                ////console.log('fetchRecords :' + JSON.stringify(response.data));
                done(response.data);
            })
            .catch(error => {
                ////console.log('fetchRecords: ' + error);
                done(null);
            });
    }

}


function fetchRecordsSQL(sql, done) {
    // //console.log('code: ' + code);

    services_api.fetch_sql(sql)
        .then(response => {
            ////console.log('sql :' + JSON.stringify(response.data));
            done(response.data);
        })
        .catch(error => {
            // //console.log('check_code: ' + error);
            done(null);
        });

}

function fetchProjects(sql, done) {
    // //console.log('code: ' + code);

    services_api.fetch_project()
        .then(response => {
            //console.log('projects :' + JSON.stringify(response.data));
            done(response.data);
        })
        .catch(error => {
            //console.log('check_code: ' + error);
            done(null);
        });

}

const api_public = {
    fetchRecords: fetchRecords,
    fetchRecordsSQL: fetchRecordsSQL,
    fetchProjects: fetchProjects
}

export default api_public;