import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextField from '@material-ui/core/TextField';
import Moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
/**
 *  fetch data
 */

import serviceData from '../api/public';


/**
 *  header 
 */

const columns = [
  {
    id: 'MaSoPhieuThu__c',
    label: 'Số phiếu thu',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'CloseDate',
    label: 'Ngày Thu',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'Amount',
    label: 'Số Tiền/Hiện Vật',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'Account.Name',
    label: 'Nhà Hảo Tâm',
    minWidth: 120,
    align: 'center'
  },
  {
    id: 'ProjectType__c',
    label: 'Mục đích',
    minWidth: 150,
    align: 'center'
  },
];



const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


function _customCampaign(data) {

  if (data != null) {
    if (data.Name != null && data.ProjectType__c != null) {
      return "" + data.Name + "(" + data.ProjectType__c + ")";
    }
    else {
      return data.Name == null ? data.ProjectType__c : data.Name;
    }

  }

  return null;
}

function _customCurrency(data) {
  if (Number.isInteger(data))
    return Number(data).toLocaleString();
  return data;
}

function _customNameDonor(data) {
  return data.PublicInfo__c === 'Tên Thật' ? data.Account.Name.replace("Household", "") : 'Ẩn Danh';
}

function _customNameDonorPublic(data) {
  if (data.PublicInfo__c !== 'Tên Thật') {
    return data.Other_Info__c != null ? data.Other_Info__c : 'Ẩn Danh';
  } else {
    return data.PublicInfo__c === 'Tên Thật' ? data.Account.Name.replace("Household", "") : 'Ẩn Danh';
  }
}


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.MaSoPhieuThu__c}
        </TableCell>
        <TableCell align="center">{row.CloseDate}</TableCell>
        <TableCell align="right">{row.Amount === 0 ? row.HienVat__c : _customCurrency(row.Amount)}</TableCell>
        <TableCell align="left">{_customNameDonor(row)}</TableCell>
        <TableCell align="left">{_customCampaign(row.Campaign)}</TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Tên Công Bố</TableCell>
                    <TableCell align="right">Cách đóng góp</TableCell>
                    <TableCell align="right">Số Tiền/Hiện Vật</TableCell>
                    <TableCell>Lời nhắn</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.MaSoPhieuThu__c}>
                    <TableCell component="th" scope="row">
                      {_customNameDonorPublic(row)}
                    </TableCell>
                    <TableCell align="right">{row.Payment_Method__c}</TableCell>
                    <TableCell align="right">{row.Amount === 0 ? row.HienVat__c : _customCurrency(row.Amount)}</TableCell>
                    <TableCell>{row.Message__c}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  logo: {
    "@media (min-width: 600px)": {
      float: 'left',
      width: '30%',
      padding: '16px',
    },
    width: '100%',
    padding: '16px',
    textAlign: 'center',
  },
  search: {
    "@media (min-width: 600px)": {
      float: 'left',
      width: '70%',
      padding: '8px',
    },
    float: 'left',
    width: '100%',
    padding: '16px',
  },


  container: {
    maxHeight: '560px',
  },
  search_logo: {
    float: 'left',
    width: '30%',
    padding: '16px',
  },
  search_field: {
    float: 'left',
    width: '70%',
    padding: '16px',
  },
})
);

export default function PagePublic() {

  const classes = useStyles();
  const [rows, setRecords] = React.useState(null);
  const [projects, setProjects] = React.useState(null)

  const [nameDonor, setNameDonor] = React.useState('');
  const [receiptID, setReceiptID] = React.useState('')
  const [targetDonation, setTargetDonation] = React.useState('')
  const [money, setMoney] = React.useState('')
  const [dateDonation, setDateDonation] = React.useState(null)

  const [project, setProject] = React.useState('Tất Cả')


  const handleProject = (event) => {
    //console.log('handleProject:' + event.target.value);
    setProject(event.target.value);
  }

  const handleNameDonor = (event) => {
    ////console.log('handleNameDonor:' + event.target.value);
    setNameDonor(event.target.value);
  }

  const handleReceiptID = (event) => {
    ////console.log('handleReceiptID:' + event.target.value);
    setReceiptID(event.target.value);
  }

  const handleTargetDonation = (event) => {
    ////console.log('handleTargetDonation:' + event.target.value);
    setTargetDonation(event.target.value);
  }

  const handleMoney = (event) => {
    ////console.log('handleMoney:' + event.target.value);
    setMoney(event.target.value);
  }

  const handleDateDonation = (date) => {
    ////console.log('handleDateDonation:' + date);
    setDateDonation(date)
  }



  const handleSearch = () => {
    var where_sql = "";
    if (receiptID !== '') {
      where_sql += " (MaSoPhieuThu__c LIKE '%25" + receiptID.trim() + "%25')"
    }

    if (nameDonor !== '') {
      if (where_sql !== "")
        where_sql += "  AND"
      where_sql += " (Account.Name LIKE '%25" + nameDonor.trim() + "%25')"
    }

    if (targetDonation !== '') {
      if (where_sql !== "")
        where_sql += "  AND"
      where_sql += " (Campaign.ProjectType__c LIKE '%25" + targetDonation.trim() + "%25'"
      where_sql += " OR Campaign.Name LIKE '%25" + targetDonation.trim() + "%25')"
    }

    if (money !== '') {
      if (where_sql !== "")
        where_sql += "  AND"
      where_sql += " (Amount = " + money.trim() + ")"
    }

    if (dateDonation !== null) {
      if (where_sql !== "")
        where_sql += "  AND"
      where_sql += " (CloseDate=" + Moment(dateDonation).format('YYYY-MM-DD') + ")"
    }
    ////console.log("search :" + where_sql);

    if (where_sql !== "") {
      where_sql = " WHERE " + where_sql;
      serviceData.fetchRecordsSQL(where_sql, setRecords);

    } else {
      if (nameDonor !== '' || receiptID !== ''
        || targetDonation !== '' || money !== ''
        || dateDonation != null) {
        serviceData.fetchRecords("", setRecords);

      }
    }

  }

  const handleClear = () => {

    if (nameDonor !== '' || receiptID !== ''
      || targetDonation !== '' || money !== ''
      || dateDonation != null || project!== '') {
      serviceData.fetchRecords("", setRecords);

    }
    setNameDonor('');
    setReceiptID('');
    setTargetDonation('');
    setMoney('');
    setDateDonation(null);
  }

  const handleKeyEnter = (event) => {

    if (event.keyCode === 13 || event.which === 13) {
      handleSearch();
    }
  }

  React.useEffect(() => {
    serviceData.fetchProjects("", setProjects);
  }, []);

  // React.useEffect(() => {
  //   serviceData.fetchRecords("", setRecords);
  // }, []);

  React.useEffect(() => {


    if(project === 'Tất Cả'){
      serviceData.fetchRecords("", setRecords);
    }else{
      let sql;
      if (project === 'Dự Án Khác') {
        sql= " WHERE Campaign.ProjectType__c = null "
      }else {
        sql= " WHERE Campaign.ProjectType__c = '" + project + "' "
      }
      
      serviceData.fetchRecordsSQL(sql, setRecords);
    }
  }, [project])

  /**
   *  page navigation
   * 
   */
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className={classes.root}>
      <div>
        <div id='logo' className={classes.logo} >
          <a href="/">
            <img src="logo.png" alt="" width="210" height="90" />
          </a>
        </div>
        <div id="search" className={classes.search}  >
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <TextField fullWidth='true' id="MaSoPhieuThu__c" label="Số phiếu thu"
                placeholder="nhập 4 số cuối"
                onChange={handleReceiptID}
                value={receiptID}
                onKeyPress={handleKeyEnter}

              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField fullWidth='true' id="PublicInfo__c" label="Tên nhà hảo tâm"
                onChange={handleNameDonor}
                value={nameDonor}
                onKeyPress={handleKeyEnter}
              />
            </Grid>

            {/* <Grid item xs={6} sm={4}>
            <TextField  fullWidth='true' id="Other_Infor__c" label="Tên công bố"  
                  onChange={} 
            value={}
            />
          </Grid> */}
            <Grid item xs={6} sm={4}>
              <InputLabel id="lbProjectType__c">Dự Án</InputLabel>
              <Select fullWidth='true' id="ProjectType__c" labelId="lbProjectType__c"
                onChange={handleProject}
                value={project}
              >
                <MenuItem value="Tất Cả" selected="true">Tất Cả</MenuItem>
                {
                  projects != null ? projects.records.map((proj) => (
                    <MenuItem value={proj.ProjectType__c == null ? 'Dự Án Khác' : proj.ProjectType__c}>{proj.ProjectType__c == null ? 'Dự Án Khác' : proj.ProjectType__c}</MenuItem>
                  )) : ''
                 
                } 
              </Select>
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField fullWidth='true' id="ProjectType__c" label="Mục đích"
                onChange={handleTargetDonation}
                value={targetDonation}
                onKeyPress={handleKeyEnter}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField fullWidth='true' id="Amount" label="Số tiền"
                onChange={handleMoney}
                value={money}
                onKeyPress={handleKeyEnter}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker fullWidth='true'
                  clearable
                  id="CloseDate" label="Ngày thu"
                  onChange={date => handleDateDonation(date)}
                  format="DD-MM-yyyy"
                  value={dateDonation}
                  onKeyPress={handleKeyEnter}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={4}>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={handleClear}
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>

      <div id="content">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label=" sticky  table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }} one
                  >
                    {column.label}
                  </TableCell>

                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows != null ? rows.records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <Row key={row.MaSoPhieuThu__c} row={row} />
                )) : ''
              }

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows != null ? rows.totalSize : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}




