import axios from "axios";

function fetch_data() {
  return axios.get('/v1/api/data');
}

function fetch_project() {
  return axios.get('/v1/api/project');
}

function check_code(code) {
  return axios.get('/v1/api/check?code=' + code);
}

function fetch_sql(sql) {
  return axios.get('/v1/api/sql?sql=' + sql);
}
const services_api = {
  fetch_data: fetch_data,
  fetch_sql: fetch_sql,
  check_code: check_code,
  fetch_project:fetch_project

}

export default services_api;