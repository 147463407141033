import React  from 'react';

import PagePublic  from './pagepublic';

class PageLayout extends React.Component {

    constructor(props){
        super(props);
        this.user = props.user;
        this.navBar = props.navBar;
        this.content = props.content;
    }



    render(){
            if(! this.user){
                return (
                    <PagePublic></PagePublic>
                );
            }
            return (
                <div>
                    <this.navBar>
                    </this.navBar>
                    <this.content>
                    </this.content>
                </div>
            );
        ;
    };
}

export default PageLayout;