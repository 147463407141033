import React from 'react';

import PageLayout from './pagelayout';



class Page extends React.Component {

    constructor(props){
        super(props);
        this.user = props.user;
        // const navBar = props.navBar;
        // const content = props.content;
    }

    render(){
        return (
            <PageLayout user={this.props.user}>
            </PageLayout>
        )
    }
}

export default Page;