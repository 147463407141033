
import React from 'react';

import Page from '../pages/page';


class App extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            isLogined: false,
        }
    }
    render(){
        return (
            <Page user={this.props.user}>
            </Page>
        )
    }
}
export default App;